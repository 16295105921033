import * as React from "react"

import Layout from "../components/Layout"
import Meta from "../components/Meta"

const NotFoundPage = () => (
  <Layout>
    <Meta
      title="404: Not found"
      description="This page was not found and caused a 404 server error."
    />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
